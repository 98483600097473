<template>
  <div class="broker-menus-nav">
    <div class="container-broker-menu">
      <nav class="broker-menus-nav-content">
        <div class="broker-menus-nav-logo">
          <img
            class="broker-menus-nav-logo-desktop"
            src="../../assets/broker-menu/logo.svg"
            alt=""
          />
          <img
            class="broker-menus-nav-logo-mb"
            src="../../assets/broker-menu/logo-mb.svg"
            alt=""
          />
        </div>

        <div class="broker-menus-nav-links">
          <ul>
            <li><a href="#table-broker-menu">Ranking</a></li>
            <li><a href="#how-to-choose">How To Choose?</a></li>
            <li><a href="#about-broker-menu">About</a></li>
          </ul>
        </div>
        <div @click="showMenu = !showMenu" class="broker-menus-nav-links-mb">
          <div
            id="nav-icon1"
            class="broker-menus-nav-links-menu"
            :class="showMenu ? 'open' : ''"
          >
            <span></span>
            <span></span>
            <span></span>
          </div>

          <div class="broker-menus-nav-links-menu-ul">
            <transition name="fade">
              <ul v-if="showMenu">
                <li><a href="#table-broker-menu">Rankin</a></li>
                <li><a href="#how-to-choose">How To Choose?</a></li>
                <li><a href="#about-broker-menu">About</a></li>
              </ul>
            </transition>
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showMenu: false,
    };
  },
};
</script>

<style>
.broker-menus-nav {
  position: relative;
  z-index: 10000;
}
/* Icon 1 */

#nav-icon1 {
  width: 35px;
  height: 3px;
  position: relative;
  top: -15px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

#nav-icon1 span {
  display: block;
  margin-bottom: 6px;
  height: 4px;
  width: 100%;
  background: #000;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

#nav-icon1 span:nth-child(1) {
  top: 0px;
}

#nav-icon1 span:nth-child(2) {
  top: 18px;
}

#nav-icon1 span:nth-child(3) {
  top: 36px;
}

#nav-icon1.open span:nth-child(1) {
  /* top: 18px; */
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
  position: absolute;
  top: 10px;
}

#nav-icon1.open span:nth-child(2) {
  opacity: 0;
  left: -60px;
}

#nav-icon1.open span:nth-child(3) {
  top: 18px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.broker-menus-nav-links-menu-ul {
  background: #fff;
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  margin-top: 20px;
}

.broker-menus-nav-links-menu-ul ul li {
  margin: 10px 0;
}
.broker-menus-nav-links-menu-ul ul li a {
  font-size: 21px;
  font-weight: 900;
  color: #000;
}
.broker-menus-nav-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 30px;
}

.broker-menus-nav-links ul {
  display: flex;
  align-items: center;
}
.broker-menus-nav-links ul li {
  margin-left: 58px;
}
.broker-menus-nav-links ul li a {
  font-size: 18px;
  font-weight: 900;
  color: #000;
}

.broker-menus-nav-links-line {
  width: 40px;
  height: 4px;
  background: #000;
  margin-bottom: 5px;
}

.broker-menus-nav-links-mb {
  display: none;
}

.broker-menus-nav-logo-mb {
  display: none;
}

@media (max-width: 768px) {
  .broker-menus-nav-links-mb {
    display: block;
    margin-top: 1px;
  }
  .broker-menus-nav-links {
    display: none;
  }
}

@media (max-width: 450px) {
  .broker-menus-nav-links-line {
    width: 30px;
    height: 3px;
    background: #000;
    margin-bottom: 5px;
  }

  .broker-menus-nav-content {
    padding-top: 15px;
  }
}

@media (max-width: 500px) {
  .broker-menus-nav-links-menu-ul ul li a {
    font-size: 18px;
  }

  .broker-menus-nav-links-menu-ul ul li {
    margin: 20px 0;
  }

  .broker-menus-nav-logo-desktop {
    display: none;
  }
  .broker-menus-nav-logo-mb {
    display: block;
  }

  .broker-menus-nav-links-mb {
    display: block;
    margin-top: 4px;
  }

  #nav-icon1 {
    margin-top: 7px;
  }

  #nav-icon1 span {
    height: 3px;
  }

  #nav-icon1.open span:nth-child(1) {
    top: 9px;
  }
}

@media (max-width: 812px) {
  .broker-menus-nav-links ul li a {
    font-size: 16px;
  }
}
</style>